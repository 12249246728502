import React, { useEffect, useState } from 'react'

import { Steps,
  Form,
  message
} from 'antd';

import 'moment/locale/es-us'

import 'html5-device-mockups/dist/device-mockups.min.css';
import 'braft-editor/dist/index.css'

import { useLazyQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { SelectorType } from './SelectorType'
import { BasicConfiguration } from './BasicConfiguration'
import { PluginFormHTML } from './Plugins';
import { IdiomaCampos, Previsualizador } from './types';
import { RESOURCE_LIST } from './connections';

// const urlUpload = 'http://localhost:4000/mtdupload'

const { Step } = Steps

// Store

const blurEventSetter = (event: React.FocusEvent<HTMLInputElement>, setter: any) => {
  const value = event.currentTarget.value
  setter(value)
}

type Params = {
  missionId?: string
}

function WarehouseCreateMission({ isMobile, exposeSubMenu, setMainLoading }: any) {
    const { missionId } = useParams<Params>()
    const [getMission, { loading, data }] = useLazyQuery(RESOURCE_LIST, {
      fetchPolicy: 'network-only'
    })
    const history = useHistory()
    
    // Token guardado en Redux
    const { token } = useSelector((state: any) => state)
    
    // Configuración del Previsualizador
    const [previewer, setPreviewer] = useState<Previsualizador>({
      name: '',
      points: '0',
      image: '',
      description: '',
      currentI18n: 'es'
    })

    // Paso actual desde el indice 0
    const [current, setCurrent] = useState<number>(0);
    // Tipo de mision a crear
    const [tipo, selectTipo] = useState<number>(0)

    // Información base de la misión
    const [missionName, setMissionName] = useState<string>('')
    const [missionDescription, setMissionDescription] = useState<string>('')
    const [missionPoints, setMissionPoints] = useState<string>('')
    const [missionImage, setMissionImage] = useState<string>('')
    // Título de la Misión por Idioma
    const [missionEnglish] = useState<string>('') // , setMissionEnglish
    const [missionPortugues] = useState<string>('') // , setMissionPortugues

    // Tipo: Formulario HTML
    const [missionCampos, setMissionCampos] = useState<Array<Array<IdiomaCampos>>>([])
    const [modalTypes, setModalTypes] = useState<boolean>(false)
    const [modalTypeSelected, setModalTypeSelected] = useState<string>('')
    const [uniqueKey, setUniqueKey] = useState<number>(0)

    // Tipo Microlearning: Videos
    const [spanishVideoName] = useState<string>('') // , setSpanishVideoName
    const [englishVideoName] = useState<string>('') // , setEnglishVideoName
    const [portuguesVideoName] = useState<string>('') // , setPortuguesVideoName

    // Estado del botón continuar
    const [nextButton, setNextButton] = useState<boolean>(false)
    // Formulario
    const [form] = Form.useForm()
    // const isFormValid = () => form.getFieldsError().some((item) => item.errors.length > 0)

    const [imageUploaded] = useState('') // , setImageURL

    const next = () => {
      if (current !== 3) {
        setCurrent(current + 1);
      }
    };

    useEffect(() => {
      setPreviewer({
        ...previewer,
        name: missionName,
        image: imageUploaded,
        points: missionPoints,
        description: missionDescription
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [missionName, missionDescription, missionPoints, imageUploaded])

    useEffect(() => {
        if ([undefined, NaN].includes(Number(missionId))) {
          setMainLoading(false)
        } else {
          /* steps.shift()
          setSteps(steps) */
          setCurrent(1)
          getMission({
              variables: {
                  ResourceListInput: {
                      clientId: 1,
                      parentId: 0
                  }
              },
              context: {
                  headers: {
                      authorization: `Bearer ${token}`
                  }
              }
          })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (data !== undefined && !loading) {
        const resource = data.resourceList?.resources?.find((resource: any) => resource.id === Number(missionId))
        if (!resource) {
          message.error('La herramienta solicitada no existe, si el problema se presenta nuevamente, contacta al administrador')
          history.push('/warehouse')
        } else {
          const { name, description, json, points, typeId, image } = resource
          console.log({ resource })
          selectTipo(typeId)
          setMissionName(name)
          setMissionDescription(description)
          setMissionPoints(points)
          setMissionImage(image)
          form.setFieldsValue({
            missionName: name,
            missionDescription: description,
            missionPoints: points,
            missionImage: image
          })
          setNextButton(true)
          /* console.log({
            json: JSON.parse(json)
          }) */
          const parsedJson = JSON.parse(json).map((item: any) => {
            const rawData = {
              ...item
            }
            delete rawData.id
            /* if (item.type === 'matrix') {
              rawData.text = `${item.label}`
              rawData.item = ''
            } 
            console.log({ rawData, item }) */
            
            if (['multiline', 'line', 'radio', 'checkbox', 'conditional', 'calendar'].includes(item.type)) {
              rawData.text = `${item.label}`
              rawData.label = null
            }

            return [{
              data: rawData,
              i18n: 'es',
              id: item.id
            }]
          })
          setMissionCampos(parsedJson)
          setTimeout(() => {
            setMainLoading(false)
          }, 2000)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
      let completed = false
      if (missionCampos.length > 0) {
        /**
         * Si es HTML, requiere missionCampos.*.data.text
         * Si es LINE, requiere missionCampos.*.data.text
         * Si es MULTILINE, requiere missionCampos.*.data.text
         * Si es UPLOAD, requiere missionCampos.*.data.text y missionCampos.*.data.label
         * Si es CALENDAR, requiere label
         */
        completed = !missionCampos.some(camposIdiomas => camposIdiomas.some(campo => 
            (campo.data.type !== 'conditional' && (campo.data.text === null || campo.data.text.length < 0))              
              || (campo.data.type === 'upload' && campo.data.label === null)
              || (campo.data.type === 'conditional' && (campo.data.ext1 === null || campo.data.ext2 === null))
        ))
      }
      console.log('isCompleted? ', completed)
      setNextButton(completed)
    }, [missionCampos, setMissionCampos])
   

    useEffect(() => {
      if ( // Spanish
          spanishVideoName.length > 0 &&
          // English
          englishVideoName.length > 0 &&
          missionEnglish.length > 0 &&
          // Portugues
          portuguesVideoName.length > 0 &&
          missionPortugues.length > 0
      ) {
        setNextButton(true)
        // console.log(editorSpanish.toRAW()) or toText()
      }
    }, [spanishVideoName, englishVideoName, portuguesVideoName, missionEnglish, missionPortugues])

    useEffect(() => {
      setModalTypeSelected('')      
    }, [modalTypes])

    
    const steps = [
      {
        title: 'Tipo',
        content: <SelectorType {...({ next, tipo, selectTipo })} />,
        description: 'Selecciona el tipo de formulario'
      },
      {
        title: 'Configuración',
        content: <BasicConfiguration {...({ missionName, tipo, current, form, nextButton, next, blurEventSetter, setMissionName, setMissionDescription, setMissionPoints, missionDescription, missionPoints, missionCampos, setMainLoading, missionImage, setMissionImage })} />,
        description: 'Completa la información'
      },
      {
        title: 'Contenido',
        content: <PluginFormHTML {...({ missionName, current, nextButton, form, next, tipo,
          modalTypes, modalTypeSelected, setModalTypes, uniqueKey, setUniqueKey, setMissionCampos, missionCampos, setModalTypeSelected,
          missionDescription, missionPoints, setMainLoading, missionImage
              })} />,
        description: 'Ingresa los campos relacionados'
      }
    ]

    return (
        <div className="App">
            <div className="WarehouseCreateMission">
                <div className="Wrapper">
                  <Steps current={current}>
                    {steps.map(item => (
                      <Step key={item.title} title={item.title} description={item.description} />
                    ))}
                  </Steps>
                  <div className="steps-content">{steps[current].content}</div>
                </div>
            </div>
        </div>
    )
}

export default WarehouseCreateMission
