import React, { useEffect } from 'react'

import { 
    UserOutlined,
    // KeyOutlined,
    // UsergroupAddOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { Avatar, PageHeader } from 'antd';

// Store
import { useSelector } from 'react-redux'

function Configuration({ isMobile, exposeSubMenu, setMainLoading }: any) {
    const { userInfo } = useSelector((state: any) => state)
    const [firstName, lastName] = userInfo.firstName.split(' ')
    const [iniF] = firstName
    const [iniL] = lastName

    useEffect(() => {
        setMainLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!!exposeSubMenu) {
            // const last = { key: 'permissions', name: 'Permisos', icon: <KeyOutlined /> }
            const last = { key: 'index', name: 'Mi perfil', icon: <UserOutlined /> }
            const option = isMobile ? [last, { key: 'user:logout', name: 'Cerrar Sesión', icon: <LogoutOutlined /> }] : [last]
            exposeSubMenu([
                /*{ key: 'usersgroups', name: 'Usuarios y Grupos', icon: <UsergroupAddOutlined /> },*/
                ...option
            ])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exposeSubMenu])

    return (
        <div className="App">
            <div className="Configuration">
                <div className="Wrapper">
                    <Avatar style={{ float: 'left', marginRight: '32px', backgroundColor: '#253040', verticalAlign: 'middle' }} size={64}>
                        {iniF}{iniL}
                    </Avatar>
                    <PageHeader
                        className="site-page-header"
                        title={userInfo.firstName}
                        subTitle='Administrador'
                    />
                </div>
            </div>
        </div>
    )
}

export default Configuration