import React, { useState } from 'react'
import {
    Form,
    Button,
    Input,
    message
} from 'antd'

import { 
    DownloadOutlined
} from '@ant-design/icons'

import axios from 'axios'
import { useSelector } from 'react-redux';
import { API_URL } from '../../Constants';

export const pad = (n: any, width: any, z = '0') => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function ReportFolderExchange() {
    const { token } = useSelector((state: any) => state)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()


    const onFinish = async (data: any) => {
        setLoading(true)

        // { data: { data: { adminCreateUser: { user } } } }
        const { data: request } = await axios.post(API_URL, {                
                query: `
                mutation adminCreateUser($AdminCreateUserInput: AdminCreateUserInput!) {
                    adminCreateUser(input: $AdminCreateUserInput) {
                      user{
                        id
                        id
                        firstName
                        mail
                        approved
                        note
                        createdAt
                      }
                    }
                  }
                `,
                variables: {
                    AdminCreateUserInput: {
                        ...data
                    }
                }
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

        console.log({ request })

        if (request.errors) {
            message.error(request.errors[0].message)
        } else {
            message.success(`Se ha creado con éxito el usuario ${request.data.adminCreateUser.user.mail}`)
            form.resetFields()
        }
        setLoading(false)
    }

    return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish} initialValues={{
        year: `${new Date().getFullYear()}`
    }}>
        <p>Completa la información del usuario para darle acceso a la plataforma.</p>
        <br />
        <Form.Item name="firstName" label="Nombre" rules={[{ required: true, message: 'Por favor, ingresa un nombre para el nuevo alumno.' }]}>
            <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Apellido" rules={[{ required: true, message: 'Por favor, ingresa el appelido del nuevo alumno.' }]}>
            <Input />
        </Form.Item>
        <Form.Item name="mail" label="Correo electrónico" rules={[{ required: true, type: 'email', message: 'Ingresa un correo electrónico válido para que pueda ingresar' }]}>
            <Input />
        </Form.Item>
        
        <Button type="primary" htmlType="submit" loading={loading} icon={<DownloadOutlined />} className='Accounting--DownloadBtn' >
          Crear usuario
        </Button>
    </Form>
}
