import React, { useEffect, useState } from 'react'

import { 
    UserSwitchOutlined,
    FileExcelOutlined,
    UnorderedListOutlined,
    UserAddOutlined
} from '@ant-design/icons';

import {
    List,
    Row,
    Col,
    Empty,
    PageHeader
} from 'antd';

import './Accounting.scss';

import ReportCatalogProducts from './ReportCatalogProducts';
import ReportFolderExchange from './ReportFolderExchange';
import DownloadReport from './DownloadReport';

function Accounting({ isMobile, exposeSubMenu, setMainLoading }: any) {
    // const { token } = useSelector((state: any) => state)
    const [active, setActive] = useState<number>(0)

    useEffect(() => {
        setMainLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // http://localhost:4001/downloads/procasur
    const data = [
        {
            id: 1,
            title: 'Listado de Usuarios',
            description: 'Muestra el listado de todos los usuarios inscritos en la plataforma de estudios.',
            icon: <UnorderedListOutlined style={{ fontSize: '32px'}} />,
            state: useState(false)
        },
        {
            id: 2,
            title: 'Crear nuevo Usuario',
            description: 'Permite crear un nuevo usuario, para que tenga el acceso a estudiar en tu plataforma.',
            icon: <UserAddOutlined style={{ fontSize: '32px'}} />,
            state: useState(false)
        },
        {
            id: 3,
            title: 'Descargar Reporte',
            description: 'Genera un reporte Excel para control de avance de los alumnos.',
            icon: <FileExcelOutlined style={{ fontSize: '32px'}}  />,
            state: useState(false)
        }
    ];

    const resetAll = () => {
        data.map(item => item.state[1](false) )
        setActive(0)
    }
    
    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Usuarios', icon: <UserSwitchOutlined /> }
                // { key: 'programming', name: 'Reportes Programados', icon: <FieldTimeOutlined /> }
            ])
        }
    }, [exposeSubMenu])

    const reportFrame = new Map<number, JSX.Element>([
        [0, <Empty description={<>Selecciona en el <strong>menú izquierdo</strong><br /> una opción para comenzar.</>} />],
        [1, <ReportCatalogProducts />],
        [2, <ReportFolderExchange />],
        [3, <DownloadReport />]
    ])

    const back = !active ? {} : {
        onBack: () => {
            resetAll()            
        }
    }

    return (
        <div className="App">
            <div className="Accounting">
                    <PageHeader
                        className="site-page-header"
                        // onBack={() => null}
                        {...back}
                        title={!active ? "Gestión de Usuarios" : "Volver a las opciones"}
                        subTitle={!active ? "Administración y gestión de alumnos" : ""}
                    />
                <div className="Wrapper">
                    <Row>
                        {(!isMobile || (isMobile && !active)) && <Col xs={24} md={6}>
                            <List
                                itemLayout="horizontal"
                                dataSource={data}
                                renderItem={item => {
                                    const [selected, setSelected] = item.state
                                    return <List.Item className={`${selected ? 'hovered' : !active ? '' : 'wohovered'}`} onClick={() => { 
                                        resetAll()
                                        setActive(selected ? 0 : item.id)
                                        setSelected(!selected)
                                    }}>
                                        <List.Item.Meta
                                            avatar={item.icon}
                                            title={item.title}
                                            description={item.description}
                                        />
                                    </List.Item>
                                }}
                            />
                        </Col>}
                        {(!isMobile || (isMobile && active > 0)) && <Col xs={24} md={18} className="Accounting--ReportFrame">
                            {reportFrame.get(active)}
                        </Col>}
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Accounting