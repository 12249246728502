import React, { useEffect, useState } from 'react'

import { Button, Descriptions, Drawer, message, Rate } from 'antd'

import {
  DownloadOutlined,
  SearchOutlined
} from '@ant-design/icons'

import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

import { Step1, Step2, Step3, Step4 } from '../../Assets/Steps'

// Store
import { useSelector } from 'react-redux'

import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table'

import { QUALIFY_USER_TAB, USER_LIST, USER_LISTA } from './connection'
import { SorterResult } from 'antd/lib/table/interface'
import moment from 'moment'

import { exportToExcel } from 'react-json-to-excel'

interface TableParams {
  pagination?: TablePaginationConfig
  sortField?: string
  sortOrder?: string
  filters?: Record<string, any>
}

const step = new Map([
  [1, Step1],
  [2, Step2],
  [3, Step3],
  [4, Step4]
])


function Postulantes({ isMobile, exposeSubMenu, setMainLoading }: any) {
  const { token } = useSelector((state: any) => state)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [open, setOpen] = useState(false)
  const [drawerData, setDrawerData] = useState<any>(null)
  const [allLoading, setAllLoading] = useState<boolean>(false)
  const [, setExcelData] = useState<any>()

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10
    }
  })

  const [qualify, e_Qualify] = useMutation(QUALIFY_USER_TAB, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })

  const { loading, data, refetch } = useQuery(USER_LIST, {
    variables: {
      UserListInput: {
        page: 1,
        paginate: 10,
        order: 'DESC',
        orderBy: 'id'
      }
    },
    nextFetchPolicy: 'cache-first',
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    notifyOnNetworkStatusChange: true
  })

  const [allRecords, e_AllRecords] = useLazyQuery(USER_LISTA, {
    variables: {
      UserListInput: {
        page: 1,
        paginate: 10000,
        order: 'ASC',
        orderBy: 'id'
      }
    },
    nextFetchPolicy: 'cache-first',
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, any>,
    sorter: SorterResult<any>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter
    })

    console.log({
      sorter,
      filters
    })

    if (pagination.current && pagination.pageSize) {
      refetch({
        UserListInput: {
          page: pagination.current,
          paginate: pagination.pageSize,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          orderBy: sorter.columnKey?.toLocaleString() || 'id'
        }
      })
    }
  }

  useEffect(() => {
    console.log('e_allRecords', e_AllRecords)
    if (e_AllRecords?.data?.UserList?.users) {
      
      const _users = e_AllRecords?.data?.UserList?.users
      const _data = []

      for (const user of _users) {
        let points = 0
        const _records = [...user.ResourceTabs].sort((a: any, b: any) => a.id - b.id)
        const record = _records.reduce(
                  (acc: any, tab: any) => {
                    // if (tab.field) 
                    acc[tab.field] = tab.value
                    points = tab.points + points
                    return acc
                  },
                  {}
                )
          
          _data.push({
            Identificador: user.id,
            Puntaje: points,
            Etapa: user.currentStep,
            Fecha: moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            ...record,
          })
      }
      // console.log(_data)
      setExcelData(_data)
      exportToExcel(_data, 'Postulantes actualizados')
      setTimeout(() => {
        setAllLoading(false)
      }, 1000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_AllRecords?.data?.UserList?.users])

  // useEffect(() => {
  //   if (excelData?.length > 0) {
  //     exportToExcel(excelData, 'Postulantes actualizados')
  //     setTimeout(() => {
  //       setAllLoading(false)
  //     }, 1000)
  //   }
  // }, [excelData])

  useEffect(() => {
    if (e_Qualify.data?.adminQualifyUserTab?.success) {
      message.success('Puntaje guardado.')
    }
  }, [e_Qualify.data])

  useEffect(() => {
    // console.log({ data })
    setDataSource(data?.UserList?.users || [])
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: data?.UserList?.total || 0
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])


  useEffect(() => {
    setMainLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setMainLoading(allLoading)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLoading])

  const columns: ColumnsType<any> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
      defaultSortOrder: 'descend'
    },
    {
      title: 'Correo',
      dataIndex: 'mail',
      key: 'mail'
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => {
        const name = record.ResourceTabs.find(
          (tab: any) => tab.field === 'Nombres'
        )?.value || <i style={{ opacity: 0.33 }}>(sin nombre)</i>
        return <>{name}</>
      }
    },
    {
      title: 'Apellido(s)',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text: any, record: any) => {
        const name = record.ResourceTabs.find(
          (tab: any) => tab.field === 'Apellidos'
        )?.value || <i style={{ opacity: 0.33 }}>(sin apellido)</i>
        return <>{name}</>
      }
    },
    {
      title: 'Fecha postulación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any, record: any) => {
        // console.log(record)
        const date = record.createdAt ? moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''
        return <>{date}</>
      }
    },
    {
      title: 'Puntaje',
      dataIndex: 'points',
      key: 'points',
      sorter: true,
      render: (text: any, record: any) => {
        // summ all ResourceTabs.points
        const points = record.ResourceTabs.reduce(
          (acc: number, tab: any) => acc + tab.points,
          0
        )
        return <>{points}</>
      }
    },
    {
      title: 'Etapa Postulación',
      dataIndex: 'currentStep',
      key: 'currentStep',
      sorter: true,
      render: (text: any, record: any) => {
        return (
          <div className='Step'>
            <img
              src={step.get(record.currentStep)}
              alt={`Paso ${step}/4`}
            />
          </div>
        )
      }
    },
    {
      title: 'Opciones',
      render: (text: any, record: any) => {
        return (
          <div className='ContainerButtons'>
            <Button
              icon={<SearchOutlined />}
              onClick={() => {
                const name =
                  record.ResourceTabs.find(
                    (tab: any) => tab.field === 'Nombres'
                  )?.value || '(sin nombre)'
                const lastName =
                  record.ResourceTabs.find(
                    (tab: any) => tab.field === 'Apellidos'
                  )?.value || '(sin apellido)'

                const _drawerData = {
                  ...record
                }

                _drawerData.fullName = `${name} ${lastName}`
                _drawerData.raw = record.ResourceTabs.reduce(
                  (acc: any, tab: any) => {
                    let key = `${tab.resourceId}.${tab.fieldId}`
                    if (key === '1.6') {
                      key = tab.field === 'Comuna' ? '1.6.1' : '1.6.2'
                    }
                    acc[key] = tab.value
                    return acc
                  },
                  {}
                )
                setDrawerData(_drawerData)
                setOpen(true)
              }}
            >
              Ver ficha
            </Button>
            {/* <Button
              icon={<FileExcelOutlined />}
              onClick={() => {
                //
              }}
            >
              Descargar
            </Button> */}
          </div>
        )
      }
    }
  ]

  
  return (
    <div className='App'>
      <div className='Postulantes'>
        <div className='Wrapper'>
        
        <Button style={{
          float: 'right',
          marginBottom: 12
        }} type="primary" loading={allLoading} disabled={loading} icon={<DownloadOutlined />} size={'large'} ghost danger onClick={() => {
          setAllLoading(true)
            allRecords()
        }}>
            Descargar base completa
          </Button>

          <Table
            loading={loading}
            rowKey={(record) => record.id}
            pagination={tableParams.pagination}
            columns={columns}
            // @ts-ignore
            onChange={handleTableChange}
            dataSource={dataSource}
          />
          <Drawer
            title={
              <>
                <img
                  src={step.get((drawerData?.currentStep ?? 0))}
                  className='TitleDraweImage'
                  alt={`Paso ${(drawerData?.currentStep ?? 0)}/4`}
                />{' '}
                Ficha de {drawerData?.fullName}
              </>
            }
            placement='right'
            width={720}
            onClose={() => {
              setOpen(false)
              setDrawerData(null)
              refetch()
            }}
            visible={open}
          >
            {drawerData && (
              <div className='DrawerContent'>
                {/* Video */}
                {drawerData?.ResourceTabs?.find(
                  (tab: any) =>
                    tab.field ===
                    'Graba tu video de hasta 1 minuto de duración.'
                ) && (
                  <div className='Video'>
                    <video
                      width='100%'
                      controls
                      controlsList=''
                      src={
                        withHttps(drawerData?.ResourceTabs?.find(
                          (tab: any) =>
                            tab.field ===
                            'Graba tu video de hasta 1 minuto de duración.'
                        )?.value)
                      }
                    >
                      Tu navegador no soporta el elemento <code>video</code>.
                    </video>
                  </div>
                )}
                {/* Notas de Enseñanza Media */}
                <Descriptions
                  title='Información Personal'
                  layout='vertical'
                  bordered
                  column={4}
                >
                  <Descriptions.Item label='Correo electrónico' span={4}>
                    {drawerData.mail}
                  </Descriptions.Item>
                  <Descriptions.Item label='Nombres' span={2}>
                    {drawerData.raw['1.1'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label='Apellidos' span={2}>
                    {drawerData.raw['1.2'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label='Cédula de Identidad' span={2}>
                    {drawerData.raw['1.3'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label='Fecha de Nacimiento' span={2}>
                    {drawerData.raw['1.4'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label='Región' span={2}>
                    {drawerData.raw['1.6.1'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label='Comuna' span={2}>
                    {drawerData.raw['1.6.2'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label='Dirección' span={2}>
                    {drawerData.raw['1.7'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label='Casa / Depto / Of' span={2}>
                    {drawerData.raw['1.8'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label='Género' span={2}>
                    {drawerData.raw['1.5'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item label='Teléfono' span={2}>
                    {drawerData.raw['1.9'] ?? <i>sin información</i>}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='Institución de Egreso de Enseñanza Media'
                    span={4}
                  >
                    {drawerData.raw['1.10'] ?? <i>sin información</i>}
                    {drawerData.raw['1.12'] && <Button
                      type='primary'
                      icon={<DownloadOutlined />}
                      size={'small'}
                      onClick={() => {
                        window.open(drawerData.raw['1.12'])
                      }}
                    >
                      Concentración de Notas
                    </Button>}
                  </Descriptions.Item>
                </Descriptions>

                {drawerData?.currentStep >= 2 && (
                  <Descriptions
                    title='Postulación'
                    style={{
                      marginTop: 24
                    }}
                    layout='vertical'
                    bordered
                    column={5}
                  >
                    <Descriptions.Item
                      label='¿Qué aspiras a ser (laboralmente)? ¿Dónde te ves en 10 años?'
                      span={3}
                    >
                      {drawerData.raw['2.1']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.1'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Por qué quieres participar en el programa PiT?'
                      span={3}
                    >
                      {drawerData.raw['2.2']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.2'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Por qué crees que deberíamos elegirte a ti? ¿Qué te distingue del resto de tus pares?'
                      span={3}
                    >
                      {drawerData.raw['2.3']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.3'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='Cuéntanos lo que crees importante saber sobre ti (Ej: quién eres, cómo te defines, historia, familia, entorno, etc.)'
                      span={3}
                    >
                      {drawerData.raw['2.4']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.4'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='Cuéntanos sobre tus hobbies, intereses, cosas que te gusta aprender por tu cuenta.'
                      span={3}
                    >
                      {drawerData.raw['2.5']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.5'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='Menciona tres valores que consideras fundamentales (los principios rectores de tu vida).'
                      span={3}
                    >
                      {drawerData.raw['2.6']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.6'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='Cuando trabajas en grupo, prefieres ser:'
                      span={3}
                    >
                      {drawerData.raw['2.7']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.7'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Cómo sueles manejar una diferencia fuerte de opinión con otra persona?'
                      span={3}
                    >
                      {drawerData.raw['2.8']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.8'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Has tomado otros cursos, diplomados o carreras previo a PiT? '
                      span={3}
                    >
                      {drawerData.raw['2.9']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.9'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Has tenido trabajos remunerados? '
                      span={3}
                    >
                      {drawerData.raw['2.10']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.10'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Te ha tocado vender alguna cosa? (ya sea por tu cuenta o como parte de un trabajo)'
                      span={3}
                    >
                      {drawerData.raw['2.11']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.11'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Alguna vez has creado alguna iniciativa o emprendido algún negocio (por pequeño que sea) por tu cuenta? '
                      span={3}
                    >
                      {drawerData.raw['2.12']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.12'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Manejas algún lenguaje de programación?'
                      span={3}
                    >
                      {drawerData.raw['2.13']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.13'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Hablas otro(s) idioma(s)?'
                      span={3}
                    >
                      {drawerData.raw['2.14']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.14'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label='¿Tienes acceso permanente a computador y conexión a internet?'
                      span={3}
                    >
                      {drawerData.raw['2.15']}
                    </Descriptions.Item>
                    <Descriptions.Item span={2}>
                      <CustomRate index={'2.15'} drawerData={drawerData} qualify={qualify} />
                    </Descriptions.Item>
                  </Descriptions>
                )}

                {/* {drawerData.ResourceTabs.sort((a: any, b: any) => a.id - b.id)
                  .filter(
                    (tab: any) =>
                      ![
                        'Concentración de Notas de Enseñanza Media',
                        'Graba tu video de hasta 1 minuto de duración.'
                      ].includes(tab.field)
                  )
                  .map((tab: any) => {
                    return (
                      <div className='Tab'>
                        <div className='TabTitle'>
                          <strong>{tab.field}</strong>
                          <div className='TabDescription'>{tab.value}</div>
                        </div>
                      </div>
                    )
                  })} */}
              </div>
            )}
          </Drawer>
        </div>
      </div>
    </div>
  )
}

const CustomRate = ({ index, drawerData, qualify }: any) => {
    const [resourceId, fieldId] = index.split('.')

    const tab = drawerData?.ResourceTabs.find(
      (_tab: any) =>
        _tab.resourceId === Number(resourceId) &&
        _tab.fieldId === Number(fieldId)
    )
    const defaultValue = tab?.points || 0
    // const [value, setValue] = useState(defaultValue)

    return (
      <Rate
        count={5}
        defaultValue={defaultValue}
        key={`Rate${index}`}
        // value={value}
        onChange={(_value) => {
          qualify({
            variables: {
              QualifyUserTabInput: {
                resourceTabId: tab?.id,
                value: _value
              }
            }
          })
          // setValue(_value)
        }}
      />
    )
  }


  const withHttps = (url: any) => url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match: any, schemma: any, nonSchemmaUrl: any) => schemma ? match : `https://${nonSchemmaUrl}`);

export default Postulantes
