import React from 'react'

import {
    Button,
    Row,
    Col,
    Result,
    Card,
    Tag,
} from 'antd';

import { TYPES } from '../constants';

type Props = {
    tipo: number
    selectTipo: React.Dispatch<React.SetStateAction<number>>
    next: () => void

}

export const SelectorType = ({ tipo, selectTipo, next }: Props) => {
    return <Row>
        <Col span={12}>
            {/* <Card size="small" hoverable onClick={() => { selectTipo(1) }} title={<>Cápsula Storyline</>} extra={<><Tag color="red">Storyline</Tag> <Tag color="red">Rise</Tag></>} style={{
                marginBottom: 12,
                opacity: tipo === 0 ? 1 : (tipo === 1 ? 1 : 0.35),
                marginTop: 18
            }}>
            <p>La carga de una tarjeta de tipo Storyline, permite que el alumno complete un paquete de aprendizaje con origen en Articulate.</p>
            </Card> */}
            <Card size="small" hoverable onClick={() => { selectTipo(2) }} title={<>Herramienta HTML</>} extra={<><Tag color="green">PDF</Tag> <Tag color="blue">Campo de Texto</Tag></>} style={{
                marginBottom: 12,
                opacity: tipo === 0 ? 1 : (tipo === 2 ? 1 : 0.35),
            }}>
            <p>La tarjeta de formulario, permite que el participante complete campos de textos y descargue archivos adjuntos (PDF, PPT, etc). Una vez completos los campos, se da por terminado.</p>
            </Card>
        </Col>
        <Col span={12}>
        {tipo > 0 && <Result
            icon={TYPES.get(tipo)?.icon}
            title={<>Formulario: <strong>{TYPES.get(tipo)?.title}</strong></>}
            subTitle="¡Una vez seleccionado el tipo, puedes continuar!."
            extra={[
            <Button type="primary" key="console" disabled={tipo === 1} onClick={() => {
                if (tipo !== 1) {
                    next()
                }
            }}>
                {tipo === 1 ? 'No disponible' : 'Iniciar Asistente'}
            </Button>,
            ]}
        />}
        </Col>
    </Row>

}