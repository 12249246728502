import React from 'react'
import Lottie, { Options } from 'react-lottie'
 
export const LottieWrapper = ({
    height = 250,
    width = 250,
    animationData
}: any) => { 
    const defaultOptions: Options = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
 
    return <Lottie options={defaultOptions}
              isClickToPauseDisabled={true}
              height={height}
              width={width} />
}
 