import {
    LOGIN_USER, LOGOUT, BLOCK_NAVIGATION, MODAL_CREATE_TYPE
} from "../../Constants"

const initialState: any = {
    token: null,
    isLogged: false,
    serverUrl: 'https://app.bodegaenlinea.cl/api/',
    userInfo: {},
    permissions: [],
    blockNavigation: false,
    modal: {
        resourceType: '',
        missionCampoId: 0,
        positive: true,
        campos: {}
    }
}

const updateState = (state: any, props: any) => Object.assign({}, state, {
    ...props
})


/**
 * Siempre retorna el state completo.
 */
function rootReducer(state = initialState, action: any) {
    switch (action.type) {
        case LOGIN_USER:
            return updateState(state, {
                ...action.payload    
            });
        case LOGOUT:
            return updateState(state, {
                token: null,
                userInfo: null,
                isLogged: false
            })
        case BLOCK_NAVIGATION:
            return updateState(state, {
                blockNavigation: action.payload
            })
        case MODAL_CREATE_TYPE:
            return updateState(state, {
                modal: {
                    ...state.modal,
                    ...action.payload
                }
            })

    }
    return state
}

export default rootReducer