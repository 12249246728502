import React, { useState, useEffect } from 'react'
import {
    Form,
    Button,
    Table,
    Popconfirm,
    message
} from 'antd'


import axios from 'axios'
import { useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import { API_URL } from '../../Constants';

export const pad = (n: any, width: any, z = '0') => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function ReportCatalogProducts() {
    const { token } = useSelector((state: any) => state)

    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm()

    const [dataSource, setDataSource] = useState<any[]>([])
    const columns: ColumnsType<any> = [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          sorter: (a: any, b: any) => a.id - b.id,
          defaultSortOrder: 'descend'
        },
        {
          title: 'Nombre',
          dataIndex: 'fullName',
          key: 'fullName',
        },
        {
          title: 'Correo electrónico',
          dataIndex: 'mail',
          key: 'mail',
        },
        /*{
          title: 'Aprobación',
          dataIndex: 'approved',
          key: 'approved',
        },*/
        {
          title: 'Puntaje',
          dataIndex: 'note',
          key: 'note',
          sorter: (a: any, b: any) => a.note - b.note,
        },
        {
            title: 'Herramientas',
            key: 'herramientas',
            render: (text: any, record: any) => {
                const [firstName,] = record.fullName.split(' ')
                return <Popconfirm
                title={`¿Estás seguro de eliminar el usuario ${record.mail}?`}
                placement='left'
                disabled={record.id === 2698}
                onConfirm={e => confirm(e, record)}
                okText="Sí"
                cancelText="No"
              >
                <Button danger size='small' disabled={record.id === 2698}>Eliminar a {firstName}</Button>
              </Popconfirm>
            }
        }
      ]

    useEffect(() => {
        loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function confirm(e: any, record: any) {
        message.success(`Se ha deshabilitado el acceso para ${record.fullName} (ID: ${record.id})`);
    }

    const loadData = async () => {
        setLoading(true)

        const { data: { data: { adminGetUserList: { users } } } } = await axios.post(API_URL, {                
                query: `
                query adminGetUserList($AdminUserListInput: AdminUserListInput!) {
                    adminGetUserList(input: $AdminUserListInput) {
                      users {
                        id
                        fullName
                        mail
                        approved
                        note
                      }
                      pagination {
                        current
                        last
                        records
                      }
                    }
                  }
                `,
                variables: {
                    AdminUserListInput: {
                        page: 1,
                        items: 1000
                    }
                }
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        
        setLoading(false)
        setDataSource(users)
    }

    return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form} initialValues={{
        filterMarcas: [],
        onlyEan: false,
        simplified: true,
        showCostos: false
    }}>
        <Table loading={loading} dataSource={dataSource} columns={columns} />
    </Form>
}