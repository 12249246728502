// @ts-ignore
const domain = new URL(window.location)
const fullname = domain.hostname.split('.').reverse()
const finalDomain = fullname.reduce((acc: string[], part, ndx) => {
    if (ndx < 2) {
        acc.push(part)
    }
    return acc
}, []).reverse().join('.')
console.log(finalDomain)

export const serverUrl = `https://api.${finalDomain}/api/`

const isProd = process.env.NODE_ENV === 'production'

export const API_URL = isProd ? `https://api-formularios.${finalDomain}/graphql` : 'http://localhost:8000/graphql'
export const SERVER_URL = isProd ? `https://api-formularios.${finalDomain}` : 'http://localhost:8000'
export const UPLOAD_URL = isProd ? `https://api-formularios.${finalDomain}/mtdupload` : 'http://localhost:8000/mtdupload'