import { gql } from '@apollo/client'

export const USER_LIST = gql`
   query UserList($UserListInput: UserListInput!) {
	UserList (input: $UserListInput) {
		pages
		total
		users {
			id
			mail
			currentStep
			currentPoints
			createdAt
			ResourceTabs {
				id
				fieldId
				points
				resourceId
				field
				value
			}
		}
	}
}
`

export const USER_LISTA = gql`
   query UserList($UserListInput: UserListInput!) {
	UserList (input: $UserListInput) {
		pages
		total
		users {
			id
			mail
			currentStep
			createdAt
			ResourceTabs {
				id
				fieldId
				points
				resourceId
				field
				value
			}
		}
	}
}
`

export const QUALIFY_USER_TAB = gql`
mutation adminQualifyUserTab($QualifyUserTabInput: QualifyUserTabInput!) {
	adminQualifyUserTab (input: $QualifyUserTabInput) {
		success
	}
}`