import { gql } from '@apollo/client'

export const RESOURCE_CREATE = gql`
   mutation resourceCreate ($ResourceCreateInput: ResourceCreateInput!) {
    resourceCreate (input: $ResourceCreateInput) {
      success
    }
  }
`

export const RESOURCE_LIST = gql`
    query resourceList ($ResourceListInput: ResourceListInput!) {
        resourceList (input: $ResourceListInput) {
        resources{
            id
            typeId
            name
            description
            image
            id
            points
            json
            createdAt
        }
        }
    }
`

export const RESOURCE_DELETE = gql`
  mutation resourceDelete ($ResourceDeleteInput: ResourceDeleteInput!) {
    resourceDelete (input: $ResourceDeleteInput) {
      success
    }
  }
`