import React from 'react'

import { LottieWrapper } from '../../Assets/Lotties/LottieWrapper';

import animCompleteForm from '../../Assets/Lotties/CompleteForm.json';
import animViewVideo from '../../Assets/Lotties/ViewVideo.json';


export const TYPES = new Map([
    [0, {
      title: '',
      icon: <></>}],
    [2, {
      title: 'Herramienta HTML',
      icon: <LottieWrapper animationData={animCompleteForm} />}],
    [1, {
      title: 'Cápsula Storyline',
      icon: <LottieWrapper animationData={animViewVideo} />}]
  ])
  
export const IDIOMAS = new Map([
    ['es', 'Formulario'],
])

export const I18NIDS = {
    es: 0
}

export const TYPESRAW = new Map([
    ['HTML', 'Código HTML'],
    ['LINE', 'Campo Línea'],
    ['MULTILINE', 'Campo Multilínea'],
    ['UPLOAD', 'Archivo adjunto'],
    ['LIKERT', 'Escala de Likert'],
    ['RADIO', 'Selección única'],
    ['MATRIX', 'Planilla Matrix'],
    ['IMAGEN', 'Imagen'],
    ['CHECKBOX', 'Selección multiple'],
    ['LIST', 'Selecciona de una lista'],
    ['CONDITIONAL', 'Condicional'],
    ['UPLOAD', 'Carga de archivo'],
    ['VIDEO', 'Grabación de video'],
    ['CALENDAR', 'Campo Calendario'],
])