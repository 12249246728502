import React, { useState } from 'react'
import {
    Form,
    Button,
    message
} from 'antd'

import { 
    DownloadOutlined
} from '@ant-design/icons'

import axios from 'axios'
import { useSelector } from 'react-redux';
import { SERVER_URL } from '../../Constants';

export const pad = (n: any, width: any, z = '0') => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function DownloadReport() {
    const { token } = useSelector((state: any) => state)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()


    const onFinish = (data: any) => {
        setLoading(true)
        message.info(`Se está generando tu reporte, por favor espera un instante...`)
        axios({
            url: `${SERVER_URL}/downloads/procasur`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                authorization: token
            }
          }).then((response) => {
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'Informe PROCASUR.csv');
             document.body.appendChild(link);
             link.click();
             setLoading(false)
          });
    }

    return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish} initialValues={{
        year: `${new Date().getFullYear()}`
    }}>
        <p>Descarga aquí el reporte completo en formato Excel.</p>
        <br />
        
        <Button type="primary" htmlType="submit" loading={loading} icon={<DownloadOutlined />} className='Accounting--DownloadBtn' >
          Descargar
        </Button>
    </Form>
}
