import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

import {
  Button,
  Modal,
  message
} from 'antd'

import { 
    HomeOutlined,
    UsergroupAddOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import { useMutation, useQuery } from '@apollo/client'
import { RESOURCE_DELETE, RESOURCE_LIST } from './connections';

// Store
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Table, { ColumnsType } from 'antd/lib/table';

function Warehouse({ isMobile, exposeSubMenu, setMainLoading }: any) {
    const { token } = useSelector((state: any) => state)
    const [dataSource, setDataSource] = useState<any[]>([])
    const history = useHistory()

    const [deleteResource, {
        loading: deleting,
        data: deletingData,
        error: deletingError
    }] = useMutation(RESOURCE_DELETE)

    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Formularios disponibles', icon: <HomeOutlined /> },
                { key: 'orderControl', name: 'Nuevo Formulario', icon: <UsergroupAddOutlined /> }
            ])
        }
    }, [exposeSubMenu])

    useEffect(() => {
      setMainLoading(deleting)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    useEffect(() => {
      if (deletingError) {
        message.error('Ha ocurrido un error al eliminar la tarjeta, intente más tarde.')
      }
      if (deletingData) {
        history.go(0)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deletingData, deletingError])

    
    const { loading, data } = useQuery(RESOURCE_LIST, {
        variables: {
            ResourceListInput: {
                clientId: 1,
                parentId: 0
            }
        },
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        setMainLoading(loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    useEffect(() => {
        if (data?.resourceList?.resources) {
            setDataSource(data.resourceList.resources)
        }
    }, [data])

    const columns: ColumnsType<any> = [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          sorter: (a: any, b: any) => a.id - b.id,
          defaultSortOrder: 'ascend'
        },
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name',
          render: (text: any, record: any) => {
            return <div style={{
                display: 'flex'
            }}>
            {/* <div style={{
                    float: 'left'
                }}>
                <img src={record.image} width={128} alt={`Imagen alternativa de la tarjeta ${record.id}`} />
            </div> */}
            <div style={{
                    float: 'left',
                    marginLeft: 12
                }}>
                <strong>{text}</strong>{record?.description && <><br /><small>{record?.description}.</small></>}
            </div>
            </div>
          }
        },
        {
          title: 'Fecha publicación',
          dataIndex: 'cretedAt',
          key: 'cretedAt',
          render: (text: any, record: any) => {
            const date = new Date(record.createdAt)
            return <>{format(
              date,
              'E dd \'de\' MMM \'de\' yyyy',
              {
                locale: es
              }
            )}</>
          }
        },
        {
          title: 'Tipo de Formulario',
          key: 'points',
          render: (text: any, record: any) => {
            const typeId = record.typeId
            const TYPES = new Map([
                [1, 'Storyline'],
                [2, 'HTML']
            ])
            return <>{TYPES.get(typeId)}</>
          },
          sorter: (a: any, b: any) => a.typeId - b.typeId,
          defaultSortOrder: 'descend'
        },
        {
          title: 'Opciones',
          render: (text: any, record: any) => {
            return <div className="ContainerButtons">
              <Button disabled={record.typeId !== 2} icon={<EditOutlined />} onClick={() => {
                Modal.confirm({
                  title: `Edición de ${record.name}`,
                  icon: <ExclamationCircleOutlined />,
                  content: <>Al editar este formulario, <strong>todas las respuesta de los participantes, quedarán en borrador</strong>
                   &nbsp;y producto de la naturaleza del cambio, esta acción es <strong>irreversible</strong>.</>,
                  okText: 'Editar',
                  cancelText: 'Volver',
                  className: 'ModalYellow',
                  onOk: () => {
                    setMainLoading(true)
                    history.push(`/warehouse/editMission/${record.id}`)
                  }
                })
              }}>Editar</Button>
              <Button danger disabled={record.typeId !== 2} icon={<DeleteOutlined />} onClick={() => {
                Modal.confirm({
                  title: 'Confirma eliminación',
                  icon: <ExclamationCircleOutlined />,
                  content: `¿Estás seguro de eliminar el formulario ${record.name}?`,
                  okText: 'Eliminar',
                  cancelText: 'Volver',
                  className: 'ModalRed',
                  onOk: () => {
                    deleteResource({
                        variables: {
                            ResourceDeleteInput: {
                              resourceId: record.id
                            }
                        },
                        context: {
                            headers: {
                              authorization: `Bearer ${token}`
                            }
                        }
                    })
                  }
                })
              }}>
              Eliminar Formulario
            </Button>
          </div>
          }
        }
        /*{
          title: 'Aprobación',
          dataIndex: 'approved',
          key: 'approved',
        },
        {
          title: 'Puntaje Final',
          dataIndex: 'note',
          key: 'note',
        },*/
      ]


    return (
        <div className="App">
            <div className="Warehouse">
                <div className="Wrapper">
                    <Table loading={loading} dataSource={dataSource} columns={columns} />
                </div>
            </div>
        </div>
    )
}

export default Warehouse