import React from 'react'

import {
    Row,
    Col,
    Form,
    Input,
    FormInstance,
    // message
} from 'antd';

import { HeaderContent } from '../HeaderContent';

import './styles.scss'

type Props = {
    missionName: string
    tipo: number
    current: number
    form: FormInstance<any>
    nextButton: boolean
    missionDescription: any
    missionPoints: any
    missionImage: any
    missionCampos: any
    next: () => any
    blurEventSetter: (e: any, setter: React.Dispatch<React.SetStateAction<any>>) => any
    setMissionName: React.Dispatch<React.SetStateAction<string>>
    setMissionDescription: React.Dispatch<React.SetStateAction<string>>
    setMissionPoints: React.Dispatch<React.SetStateAction<string>>
    setMissionImage: React.Dispatch<React.SetStateAction<string>>
    setMainLoading: (bool: boolean) => React.Dispatch<React.SetStateAction<boolean>>
}

// const beforeUpload = (file: any) => {
//     const isJpgOrPng = ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type);
//     if (!isJpgOrPng) {
//         message.error('Solamente puedes cargar imagen en formato PNG o JPG.');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 1;
//     if (!isLt2M) {
//         message.error('El tamaño de la imagen no debe ser superior a 1MB.');
//     }
//     return isJpgOrPng && isLt2M;
// }

export const BasicConfiguration = ({
    missionName,
    tipo,
    current,
    form,
    nextButton,
    next,
    blurEventSetter,
    setMissionName,
    setMissionDescription,
    setMissionPoints,
    missionDescription,
    missionPoints,
    missionCampos,
    setMainLoading,
    missionImage,
    setMissionImage
}: Props) => {
    const { TextArea } = Input;
    // const [imageUploaded, setImageURL] = useState('')

    // const handleChange = (info: any) => {
    //     if (info.file.status === 'uploading') {
    //         setMainLoading(true)
    //         return;
    //     }
    //     if (info.file.status === 'done') {
    //         // Get this url from response in real world.
    //         /* setMission({
    //             ...mission,
    //             image: info.file.response.url
    //         }) */
    //         setMissionImage(info.file.response.url)
    //         setMainLoading(false)
    //         /* getBase64(info.file.originFileObj, (imageUrl: any) => {
    //             setMainLoading(false)
    //             setImageURL(imageUrl)
    //         }); */
    //     }
    // };

    // const uploadButton = (
    //     <div>
    //       <div style={{ marginTop: 8 }}>Seleccionar imagen...</div>
    //     </div>
    // );

    return <>
        <HeaderContent current={current} form={form} missionName={missionName} next={next} nextButton={nextButton} tipo={tipo} missionCampos={missionCampos} missionDescription={missionDescription} missionPoints={missionPoints} missionImage={missionImage} />
        <Row>
            <Col span='auto' style={{ width: 'calc(100% - 230px)' }}>
                <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} form={form} onFinish={(values: any) => {
                        console.log({ values })
                        next()
                    }} >
                        <Form.Item name="missionName" tooltip="El nombre que definas acá, será de uso interno." label="Nombre" rules={[{ required: true, message: 'Por favor, ingresa un nombre para el formulario' }]}>
                            <Input onBlur={(e) => blurEventSetter(e, setMissionName)} />
                        </Form.Item>
                        <Form.Item name="missionDescription" label="Descripción" >
                            <TextArea rows={3} onBlur={(e) => blurEventSetter(e, setMissionDescription)} />
                        </Form.Item>
                        {/* <Form.Item name="missionImage" label="Imagen"  rules={[{ required: true, message: 'Por favor, sube una imagen para la tarjeta.' }]}>
                        <Upload
                            name="missionMedia"
                            listType="picture-card"
                            className="missionMedia-uploader"
                            showUploadList={false}
                            action={UPLOAD_URL}
                            beforeUpload={beforeUpload}

                            onChange={handleChange}
                        >
                            {missionImage ? <img src={missionImage} alt="missionImage" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                        </Form.Item> */}
                        {/*<Form.Item name="missionImage" label="Insignia"  rules={[{ required: true, message: 'Por favor, sube una insignia para la misión.' }]}>
                            <Upload
                            name="missionMedia"
                            listType="picture-card"
                            className="missionMedia-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            // action={UPLOAD_URL}
                            beforeUpload={(file: any) => {
                                const isJpgOrPng = file.type === 'image/png';
                                if (!isJpgOrPng) {
                                    message.error('Solamente puedes cargar imagen en formato PNG con fondo transparente.');
                                    return false
                                }
                                const isLt2M = file.size / 1024 / 1024 < 1;
                                if (!isLt2M) {
                                    message.error('La insignia no debe ser superior a 1MB!');
                                    return false
                                }
                                return isJpgOrPng && isLt2M;
                            }}
                            onChange={(info: any) => {
                                if (info.file.status === 'uploading') {
                                  setMainLoading(true)
                                  return;
                                }
                                if (info.file.status === 'done') {
                                  // Get this url from response in real world.
                                  console.log({
                                      image: info.file.response.url
                                  })
                                  /* setMission({
                                    ...mission,
                                    image: info.file.response.url
                                  }) *--
                                  // setMissionImage(info.file.response.url)
                                  getBase64(info.file.originFileObj, (imageUrl: any) => {
                                    setMainLoading(false)
                                    setImageURL(imageUrl)
                                    console.log({
                                        imageUrl
                                    })
                                  });
                                }
                            }}
                            >
                            {imageUploaded ? <img src={imageUploaded} alt="missionImage" style={{ width: '100%' }} /> : <div>
                                <div style={{ marginTop: 8 }}>Seleccionar...</div>
                            </div>}
                            </Upload>
                        </Form.Item>*/}

                        {/* <Form.Item name="missionPoints" label="Puntaje" rules={[{ required: true, message: 'Por favor, ingresa puntaje para la obtención de la tarjeta.' }]}>
                            <InputNumber min={0} onBlur={(e) => blurEventSetter(e, setMissionPoints)} />
                        </Form.Item> */}
                </Form>
            </Col>
        </Row>
    </>

}