import axios from 'axios'

import {
    LOGIN_USER,
    API_URL
} from "../../Constants";


export function attemptLogin({ email: mail, password }: any){
    return async (dispatch: any) => {
        return axios
            .post(API_URL, {
                query: `
                mutation UserLogin($UserLoginInput: UserLoginInput!) {
                    UserLogin(input: $UserLoginInput) {
                      success
                      state
                      text
                      token
                      GroupInfo {
                        id
                        userGroupId
                        internalName
                        name
                      }
                    }
                  }
                `,
                variables: {
                    UserLoginInput: {
                        mail,
                        password
                    }
                }
            })
            .then((response: any) => {
                console.log({ response })
                const firstName = `Administrador ${response.data.data.UserLogin.GroupInfo.name}`
                /*const permissions = response.data.user.permissionInfo.reduce((acc: string[], perm: any) => {
                    acc.push(perm.permission)
                    return acc
                }, [])*/

                dispatch({
                    type: LOGIN_USER,
                    payload: {
                        token: response.data.data.UserLogin.token,
                        userInfo: {
                            ...response.data.data.UserLogin,
                            firstName
                        },
                        permissions: [],
                        isLogged: true
                    }
                })
                return response.data.data.UserLogin
            })
            .catch(err => {
                dispatch({
                    type: LOGIN_USER,
                    payload: {
                        token: null,
                        userInfo: null,
                        isLogged: false
                    }
                })
                return err.message
            })
    }
}

export function logout(payload: any){
    return { type: 'LOGOUT', payload }
}

export function setNavigation(payload: boolean){
    return { type: 'BLOCK_NAVIGATION', payload }
}

export function setModal(payload: any){
    return { type: 'MODAL_CREATE_TYPE', payload }
}