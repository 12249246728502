import React, { useEffect } from 'react'

import {
    Button,
    Row,
    Col,
    PageHeader,
    FormInstance,
    Modal,
    message
} from 'antd';

import { TYPES } from './constants';
import './HeaderContent.scss'
import { RESOURCE_CREATE } from './connections';

import { useMutation } from '@apollo/client'

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'

import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { setNavigation } from '../../Store/Actions';

export interface HeaderContentProps {
    missionName: string
    current: number
    nextButton: boolean
    form: FormInstance<any>
    next: () => any
    tipo: number
    missionDescription: string
    missionPoints: number,
    missionCampos: any
    missionImage: any
}

type Params = {
    missionId?: string
}

type CreateInput = {
    typeId: number
    name: string
    description: string
    image: string
    points: number
    json: string
    edit?: boolean
    missionId?: number
}

export const HeaderContent = ({ missionName, current, nextButton, form, next, tipo, missionDescription, missionPoints, missionCampos, missionImage }: HeaderContentProps) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { missionId } = useParams<Params>()
    const { userInfo: { token }, blockNavigation } = useSelector((state: any) => state)

    const [publish, {
        loading,
        data
    }] = useMutation(RESOURCE_CREATE)

    useEffect(() => {
        if (!blockNavigation) {
            message.success('La tarjeta fue creada exitosamente, y ya está disponible para los participantes')
            history.push(`/warehouse/`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockNavigation])

    useEffect(() => {
        if (data?.resourceCreate?.success!) {
            dispatch(setNavigation(false))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const isEdit = missionId && typeof missionId === 'string' && missionId.length > 0

    const getText = (campo: any) => {
        if (['video', 'upload', 'html'].includes(campo[0].data.type)) {
            return campo[0].data.text
        }
        return null
    }

    const getLabel = (campo: any) => {
        if (campo[0].data.type === 'html') {
            return null
        } else if (['video', 'upload'].includes(campo[0].data.type)) {
            return campo[0].data.label
        } else {
            return campo[0].data.text
        }
    }

    const doPublish = () => {
        const json = JSON.stringify(missionCampos.map((campo: any, id: number) => {
                      
            const { extra, ext1, ext2, ext3 } = campo[0].data

            const text = getText(campo)
            const label = getLabel(campo)

            return {
                id,
                type: campo[0].data.type,
                text,
                label,
                extra,
                ext1,
                ext2,
                ext3
            }
        }))
        const ResourceCreateInput: CreateInput = {
            typeId: tipo,
            name: missionName,
            description: missionDescription,
            image: missionImage,
            points: Number(missionPoints),
            json
        }

        if (isEdit) {
            ResourceCreateInput.missionId = Number(missionId)
            ResourceCreateInput.edit = true
        }
        
        publish({
            variables: {
                ResourceCreateInput
            },
            context: {
                headers: {
                  authorization: `Bearer ${token}`
                }
            }
        })
    }
    
    return <Row>
        <Col span={24}>
            <PageHeader
                title={<>{missionName === '' ? <i>Nombre del formulario</i> : <>"{missionName}"</>}</>}
                subTitle={<><strong>Tipo de formulario: </strong>{TYPES.get(tipo)?.title}</>}
                extra={[
                    <Button key="1" loading={loading}  danger={current !== 2 ? false : true} disabled={current === 1 ? false : !nextButton} type="primary" onClick={() => {
                    if (current === 1) {
                        form.submit()
                    } else if(current === 2) {
                        Modal.confirm({
                            title: isEdit ? 'Confirma edición de tarjeta' : 'Confirma publicación de tarjeta',
                            icon: <ExclamationCircleOutlined />,
                            okText: 'Publicar',
                            cancelText: 'Volver',
                            className: 'ModalRed',
                            onOk: () => {
                                doPublish()
                            }
                        })
                    } else {
                        next()
                    }
                    }}>
                    {current !== 2 ? 'Continuar' : isEdit ? 'Editar' : 'Publicar'}
                    </Button>,
                ]}
            />
        </Col>
    </Row>
}