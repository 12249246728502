import React, { useState } from 'react'
import Lottie, { Options } from 'react-lottie'
 
import { 
    Row,
    Col,
    Tooltip
  } from 'antd';

export const LottieFlow = ({
    height = 48,
    width = 48,
    animationData,
    title,
    description,
    onClick,
    style,
    disabled
}: any) => {
    const [stop, setStop] = useState<boolean>(true)

    const defaultOptions: Options = {
      loop: true,
      autoplay: false,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    };
 
    return <Row onClick={() => { if (!disabled) { onClick() } }}
                wrap={false}
                style={{ cursor: 'pointer', opacity: !disabled ? (stop ? 0.35 : 1) : 0.1, ...style }}
                onMouseEnter={() => { if(!disabled) { setStop(false) } }}
                onMouseLeave={() => { if(!disabled) { setStop(true) } }}>
        <Tooltip title={!disabled ? description : 'En este momento el recurso no está disponible, intente más tarde'} color={!disabled ? 'black' : 'red'}>
            <Col flex={'50px'}>
                <Lottie options={defaultOptions}
                    isClickToPauseDisabled={true}
                    style={{
                        color: stop ? '#333' : '#1890ff'
                    }}
                    isPaused={stop}
                    height={height}
                    width={width} />
            </Col>
            <Col flex={'auto'}>
                    <strong>{title}</strong>
            </Col>
        </Tooltip>
    </Row>
}
 