import React from 'react'
import { Helmet } from 'react-helmet'

import { Provider } from 'react-redux'
import configureStore from './Store'
import { PersistGate } from 'redux-persist/integration/react'

import { BrowserRouter, Switch } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import esES from 'antd/lib/locale/es_ES'

import './App.scss'

// Vistas
import {
  Login,
  DashboardIndex,
  WarehouseIndex,
  AccountingIndex,
  ConfigurationIndex,
  WarehouseCreateMission,
  PostulantesIndex
} from './Views'

// Router
import { PublicRoute, PrivateRoute } from './Routes'
import AccountingProgramming from './Views/Accounting/Programming'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { API_URL } from './Constants'
// import firebase from 'firebase'

const { persistor, store } = configureStore()

const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache()
});


function App() {

  return <ApolloProvider client={client}><ConfigProvider locale={esES}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Helmet titleTemplate="Administrador - %s" />
            <Switch>
              <PublicRoute restricted={true} component={Login} path="/" exact />
              <PrivateRoute component={Login} path="/secret" exact />
              <PrivateRoute component={PostulantesIndex} path="/postulantes" exact />
              <PrivateRoute component={AccountingIndex} path="/accounting" exact />
              <PrivateRoute component={DashboardIndex} path="/dashboard" exact />
              <PrivateRoute component={WarehouseIndex} path="/warehouse" exact />
              <PrivateRoute component={WarehouseCreateMission} path="/warehouse/orderControl" exact />
              <PrivateRoute component={WarehouseCreateMission} path="/warehouse/editMission/:missionId" exact />
              <PrivateRoute component={AccountingProgramming} path="/accounting/programming" exact />
              <PrivateRoute component={ConfigurationIndex} path="/configuration" exact />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
      </ConfigProvider>
    </ApolloProvider>
}

export default App