import React, { useState } from 'react'

import {
    Row,
    Col,
    Input, 
    Modal,
    Button,
    Empty,
    Divider,
    Tabs,
    InputNumber,
    Result,
    message
} from 'antd';

import prompt from 'antd-prompt'


import {
  AppstoreAddOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined
} from '@ant-design/icons';

import BraftEditor from 'braft-editor'
import ReactDataGrid from 'react-data-grid'
// import { ContentUtils } from 'braft-utils'

import { HeaderContent, HeaderContentProps } from '../HeaderContent';


import { IDIOMAS, TYPESRAW, I18NIDS } from '../constants';
import { IdiomaCampos } from '../types'

import { LottieFlow } from '../../../Assets/LottieFlow/LottieFlow';

import animLine from '../../../Assets/LottieFlow/Line.json'
import animMultiline from '../../../Assets/LottieFlow/Multiline.json'
import animHTMLCog from '../../../Assets/LottieFlow/HTMLCog.json'
import animAttachment from '../../../Assets/LottieFlow/Attachment.json'
import animRadioOption from '../../../Assets/LottieFlow/RadioOption.json'
import animCheckboxOption from '../../../Assets/LottieFlow/CheckboxOption.json'
import animConditional from '../../../Assets/LottieFlow/Conditional.json'
import animImagen from '../../../Assets/LottieFlow/Imagen.json'

import { setModal } from '../../../Store/Actions';
import { useDispatch, useSelector } from 'react-redux';

interface Props extends HeaderContentProps {
    modalTypes: any
    modalTypeSelected: any 
    missionCampos: Array<Array<IdiomaCampos>> 
    uniqueKey: any
    setModalTypes: React.Dispatch<React.SetStateAction<any>> 
    setUniqueKey: React.Dispatch<React.SetStateAction<any>> 
    setMissionCampos: React.Dispatch<React.SetStateAction<Array<Array<IdiomaCampos>>>> 
    setModalTypeSelected: React.Dispatch<React.SetStateAction<any>>
    missionDescription: any
    missionPoints: any
    setMainLoading?: React.Dispatch<React.SetStateAction<boolean>> 
    missionImage: any
}
const { TabPane } = Tabs

export default function PluginFormHTML ({ missionName, current, nextButton, form, next, tipo,
    modalTypes, modalTypeSelected, setModalTypes, uniqueKey, setUniqueKey, setMissionCampos, missionCampos, setModalTypeSelected,
    missionDescription, missionPoints, missionImage
}: Props) {
    const [create, setCreate] = React.useState<number>(1)
    const [local, setLocal] = React.useState<boolean>(false)

    const { modal } = useSelector((state: any) => state)

    React.useEffect(() => {
        setCreate(1)
    }, [missionCampos])

    const createField = (ammount = 1) => {
      const placeholderText = new Map([
          ['es', `Escribe aquí (máximo ${modalTypeSelected === 'multiline' ? '250' : '150'} caracteres)...`],
          ['en', `Write here (maximum ${modalTypeSelected === 'multiline' ? '250' : '150'} characters)...`],
          ['pt', `Escreva aquí (máximo ${modalTypeSelected === 'multiline' ? '250' : '150'} caracteres)...`],
      ])

      let campos = []
      let internalUniqueKey = uniqueKey
      for (let index = 0; index < ammount; index++) {
        const i18ns = Array.from(IDIOMAS, idioma => idioma[0])
        const id = internalUniqueKey + 2
        setUniqueKey(id)
        internalUniqueKey = id


        // @ts-ignore
        const instanceIdiomaCampo: Array<IdiomaCampos> = [
            ...i18ns.map((i18n) => {
            return {
                  id,
                  i18n,
                  data: {
                  type: modalTypeSelected,
                  text: null, // `Bloque de ${modalTypeSelected.toUpperCase()}`
                  // label: modalTypeSelected === 'upload' ? 'enabled' : null,
                  label: null,
                  placeholder: ['multiline', 'line'].includes(modalTypeSelected) ? placeholderText.get(i18n) : null,
                  extra: null,
                  ext1: null,
                  ext2: modalTypeSelected === 'likert' ? 5 : null,
                  ext3: null
                }
            }
            })
        ]

        campos.push(instanceIdiomaCampo)
      }
      
      console.log({
        local
      })
      if (local) {
        // positive === ext1
        // negative === ext2
        if (modal.positive) {
          missionCampos[modal.missionCampoId][0].data.ext1 = campos // [0][0].data
        } else {
          missionCampos[modal.missionCampoId][0].data.ext2 = campos // [0][0].data
        }
        setMissionCampos([
          ...missionCampos
        ])
        /* dispatch(setModal({
          resourceType: modalTypeSelected,
          campos
        })) */
        console.log({
          campos
        })
      } else {
        setMissionCampos([
            ...missionCampos,
            ...campos
        ])
      }

      // Restore local
      setLocal(false)
      // Hide modal
      setModalTypes(false)
    }
    return <>
        <HeaderContent current={current} form={form} missionName={missionName} next={next} nextButton={nextButton} tipo={tipo} missionCampos={missionCampos} missionDescription={missionDescription} missionPoints={missionPoints} missionImage={missionImage} />
        <Row>
            <Col span='auto' style={{ width: 'calc(100%)' }}>
                <Modal
                    width={800}
                    title="Selecciona el recurso a utilizar" 
                    onCancel={() => {
                      // Restore local
                      setLocal(false)
                      // Hide modal
                      setModalTypes(false)
                    }}
                    visible={modalTypes}
                    footer={<Row>
                      <Col>
                        {!local && <InputNumber value={create} min={1} max={10} defaultValue={1} onChange={(value) => { setCreate(Number(value)) }} />}
                      </Col>
                      <Col>
                        <Button onClick={() => {
                          // Restore local
                          setLocal(false)
                          // Hide modal
                          setModalTypes(false)
                        }}>Cancelar</Button>
                        <Button type='primary' disabled={modalTypeSelected === ''} onClick={() => createField(create)}>Añadir{modalTypeSelected !== '' && ` ${TYPESRAW.get(modalTypeSelected.toUpperCase())}`}</Button>
                      </Col>
                    </Row>}
                    className='ModalResourceSelect'>
                    {/*<div>*/}
                        {/*<LottieFlow style={{ ...(modalTypeSelected === 'upload' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('upload')} animationData={animAttachment} title='Archivo adjunto' description='Permite la carga de una fotografia, capturada o de la galeria del participante.' />
                    </div>
                    <br />
                  <div>*/}
                  <div>
                    <small>Recursos estáticos</small>
                    <div>
                        <LottieFlow style={{ ...(modalTypeSelected === 'html' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('html')} animationData={animHTMLCog} title='HTML' description='Incorpora un bloque de texto enriquecido, puedes incluir títulos y enlaces útiles.' />
                        {/* <LottieFlow disabled style={{ ...(modalTypeSelected === 'image' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('image')} animationData={animImagen} title='Imagen' description='Añade una imagen embebida en el recurso, directamente desde nuestros servidores.' /> */}
                    </div>
                  </div>
                  <div>
                    <small>Campos interactivos</small>
                    <div>
                        <LottieFlow style={{ ...(modalTypeSelected === 'line' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('line')} animationData={animLine} title='Input Línea' description='Un input de una sola línea permite el ingreso de hasta 150 caracteres en una unica línea de texto.' />
                        <LottieFlow style={{ ...(modalTypeSelected === 'multiline' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('multiline')} animationData={animMultiline} title='Input Multilínea' description='Un campo multilínea permite que el participante pueda escribir hasta 250 caracteres.' />
                        <LottieFlow style={{ ...(modalTypeSelected === 'calendar' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('calendar')} animationData={animLine} title='Input Calendario' description='Picker de fecha.' />
                        <LottieFlow style={{ ...(modalTypeSelected === 'upload' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('upload')} animationData={animAttachment} title='Carga de Archivo' description='Permite la carga de archivos.' />
                        <LottieFlow style={{ ...(modalTypeSelected === 'video' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('video')} animationData={animImagen} title='Graba video' description='Graba video.' />
                    </div>
                  </div>
                  <div>
                    <small>Campos de opciones</small>
                    <div>
                        <LottieFlow style={{ ...(modalTypeSelected === 'radio' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('radio')} animationData={animRadioOption} title='Selección única' description='Permite generar un campo para seleccionar una opción única.' />
                        <LottieFlow style={{ ...(modalTypeSelected === 'checkbox' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('checkbox')} animationData={animCheckboxOption} title='Selección multiple' description='Permite generar un campo para seleccionar una o más opciones.' />
                        {/* <LottieFlow disabled style={{ ...(modalTypeSelected === 'list' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('list')} animationData={animListOption} title='Selecciona de una lista' description='Permite generar un campo para seleccionar una opción de una lista.' />  */}
                        {/* <LottieFlow style={{ ...(modalTypeSelected === 'likert' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('likert')} animationData={animLikert} title='Escala de Likert' description='Integra el conocido instrumento de medición psicométrico.' /> */}
                    </div>
                  </div>
                  {!local && <div>
                    <small>Avanzados</small>
                    <div>
                        <LottieFlow style={{ ...(modalTypeSelected === 'conditional' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('conditional')} animationData={animConditional} title='Condicional' description='Configura un campo alternado para condicionar el contenido mostrado.' />
                        {/* <LottieFlow style={{ ...(modalTypeSelected === 'matrix' ? { opacity: 1 } : {}) }} onClick={() => setModalTypeSelected('matrix')} animationData={animMatrix} title='Planilla Matrix' description='Permite crear una tabla matrix tipo planilla, para que se completen los contenidos.' /> */}
                    </div>
                  </div>}
                    {/*</div>*/}
                </Modal>
                {!missionCampos.length && <Empty
                    description={<><strong>¡Crea el primer campo del formulario!</strong><br />Haz click en el botón y selecciona el tipo de dato a incluir.</>}
                    image={<AppstoreAddOutlined twoToneColor='#888' style={{
                        fontSize: 96,
                        color: '#333'
                    }} />}>
                    <Button shape='round' danger onClick={() => setModalTypes(true)}>Crear nuevo campo</Button>
                </Empty>}
                {missionCampos.map((campo, indice) => {
                    const tabs = Array.from(IDIOMAS, idioma => idioma[1])
                    const typeLocal = campo[0].data.type
                    const id = campo[0].id

                    const deleteField = () => {
                    Modal.confirm({
                        title: 'Eliminar campo',
                        icon: <ExclamationCircleOutlined />,
                        content: '¿Estás seguro de eliminar este campo?',
                        okText: 'Sí',
                        cancelText: 'Volver',
                        onOk: () => {
                        // console.log('eliminando wea', indice)
                        // const data = Array.from(missionCampos).filter((campo, ndx) => ndx !== indice)
                        const data = Array.from(missionCampos)
                        data.splice(indice, 1)
                        setMissionCampos(data)
                        }
                    })
                    }

                    return <React.Fragment key={`MissionCampo-${id}`}><Tabs type="card" defaultActiveKey="1" tabBarExtraContent={<>
                      <Button onClick={deleteField}>Eliminar</Button>
                      {/* Up */}
                      {indice > 0 && <Button onClick={() => {
                        const data = Array.from(missionCampos)
                        const temp = data[indice]
                        data[indice] = data[indice-1]
                        data[indice-1] = temp
                        setMissionCampos(data)
                      }}><ArrowUpOutlined /></Button>}
                      {/* Down */}
                      {indice < missionCampos.length-1 && <Button onClick={() => {
                        const data = Array.from(missionCampos)
                        const temp = data[indice]
                        data[indice] = data[indice+1]
                        data[indice+1] = temp
                        setMissionCampos(data)
                      }}><ArrowDownOutlined /></Button>}
                    </>}>
                    <TabPane tab={<><InfoCircleOutlined /><strong>Tipo: </strong>{TYPESRAW.get(typeLocal.toUpperCase())}</>} key="0" disabled={true} />
                    {tabs.map((idioma, index) => <TabPane tab={idioma} key={index+1}>
                        {/* @ts-ignore */}
                        <TabContentForm {...campo[index]} indice={indice} missionCampos={missionCampos} setMissionCampos={setMissionCampos} setLocal={setLocal} setModalTypes={setModalTypes} />
                    </TabPane>
                    )}
                    </Tabs><Divider /></React.Fragment>
                })}
                {missionCampos.length > 0 && <Button className="AddedNewItem" onClick={() => setModalTypes(true)} type="link" icon={<PlusCircleOutlined />}><span className="text"><strong>Añadir nuevo campo al final de la lista</strong><br />Mostrar selector de recursos...</span></Button>}
            </Col>
        </Row>
    </>
}

interface TabContentProps {
  data: any
  i18n: string
  indice: any
  missionCampos: Array<Array<IdiomaCampos>>
  setMissionCampos: React.Dispatch<React.SetStateAction<Array<Array<IdiomaCampos>>>> 
  setLocal?: React.Dispatch<React.SetStateAction<boolean>>
  setModalTypes?: React.Dispatch<React.SetStateAction<boolean>>
}

const TabContentForm = ({ data, i18n, indice, missionCampos, setMissionCampos, setLocal, setModalTypes }: TabContentProps) => {
    const typeLocal = data.type
  
    const [editor, setEditor] = useState<BraftEditor>(BraftEditor.createEditorState(data.text || null))
    const [radios, setRadios] = useState<any[]>([])

    const [columns, setColumns] = useState<any[]>([{
      key: 'col0', name: ' ', editable: true, headerRender: true
    }])
    const [rows, setRows] = useState<any[]>([])
    
    const { modal } = useSelector((state: any) => state)

    const dispatch = useDispatch()

    React.useEffect(() => {
      if (typeLocal === 'matrix') {
        // @ts-ignore
        const updated = Array.from(missionCampos)
        /* updated[indice][I18NIDS[i18n]].data.text = JSON.stringify({
          columns,
          rows
        }) */
        let valid = null
        const columnsOK = columns.length - 1 >= 1
        const rowsOK = rows.length > 0 && rows.filter(row => row.col0 !== undefined && row.col0.trim() !== '').length === rows.length
        if (columnsOK && rowsOK) {
          valid = JSON.stringify({
            columns,
            rows
          })
        }
        // @ts-ignore
        updated[indice][I18NIDS[i18n]].data.text = valid
        setMissionCampos(updated)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, rows])

    React.useEffect(() => {
      console.log({
        a: modal.campos
      })
    }, [modal.campos])

    React.useEffect(() => {
      if (typeLocal === 'matrix') {
        const dataFromMission = missionCampos[indice]
        const labelRaw = dataFromMission[0]?.data.label
        if (labelRaw && labelRaw !== null) {
          const { columns, rows } = JSON.parse(labelRaw!)
          if (columns && rows) {
            setColumns(columns)
            setRows(rows)
          }
        }
      }
      if (['radio', 'checkbox'].includes(typeLocal) && typeof JSON.parse(missionCampos[indice][0].data.ext1) === 'object' && JSON.parse(missionCampos[indice][0].data.ext1) !== null && JSON.parse(missionCampos[indice][0].data.ext1).length !== undefined) {
        // @ts-ignore
        setRadios(JSON.parse(missionCampos[indice][0].data.ext1))

        console.log({
          a: missionCampos[indice]
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* const columns = [
      { key: 'id', name: 'ID' },
      { key: 'title', name: 'Title' },
      { key: 'count', name: 'Count' } ];
    
    const rows = [{id: 0, title: 'row1', count: 20}, {id: 1, title: 'row1', count: 40}, {id: 2, title: 'row1', count: 60}]; */

    
    return <React.Fragment>
      {typeLocal === 'html'&& <BraftEditor
          language='en'
          className="EditorContentB"
          controls={['headings','separator','bold', 'italic', 'underline', 'separator', 'list-ol', 'list-ul', 'separator', 'link']} // , 'media', 'link' 
          placeholder={`Comienza a escribir tu texto enriquecido para la tarjeta de ${IDIOMAS.get(i18n)}.`}
          style={{
            height: 400
          }}
          value={editor}
          onChange={(editorState) => {
            setEditor(editorState)
          }}
          onBlur={(editorState: any) => {
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.text = editorState.toHTML()
            setMissionCampos(updated)
          }}
          />}
      {['line', 'multiline', 'calendar'].includes(typeLocal) && <>
          {/* @ts-ignore */}
        <p>Para el ingreso de este {TYPESRAW.get(typeLocal?.toUpperCase()).toLowerCase()}, debes ingresar la instrucción asociada al campo.</p>
        <Input
          // @ts-ignore
          defaultValue={missionCampos[indice][I18NIDS[i18n]].data.text}
          onBlur={({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
            // @ts-ignore
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.text = value
            setMissionCampos(updated)
          }}
          style={{
            marginBottom: 12
          }}
          // @ts-ignore
          placeholder={`Ingresa la instrucción asociada a este ${TYPESRAW.get(typeLocal.toUpperCase()).toLowerCase()}.`} />
      </>}
      {typeLocal === 'conditional' && <>
        <p>Para el ingreso de un campo condicional, es necesario configurar los textos de acción <strong>positiva</strong> y <strong>negativa</strong>.</p>

        <Input
          // @ts-ignore
          defaultValue={missionCampos[indice][I18NIDS[i18n]].data.text}
          onBlur={({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
            // @ts-ignore
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.text = value
            setMissionCampos(updated)
          }}
          style={{
            marginBottom: 12
          }}
          // @ts-ignore
          placeholder={`Ingresa la instrucción asociada a este bloque condicional...`} />

        <Row className='SpaceConditional'>
          <Col span={12}>
            {data.ext1 === null ? <Result
              status='success'
              title={<>Ingresa el contenido para la <strong>respuesta positiva</strong> en este lugar</>}
              extra={
              <Button type="dashed" onClick={() => {
                setLocal!(true)
                dispatch(setModal({
                  positive: true,
                  missionCampoId: indice
                }))
                setModalTypes!(true)
              }}>
                Seleccionar Recurso
              </Button>
              } /> : <><strong>Acción positiva: </strong><br /><TabContentForm data={data.ext1[0][0].data} i18n={i18n} indice={0} missionCampos={data.ext1} setMissionCampos={(extdata) => {
                const updated = Array.from(missionCampos)
                console.log({
                  extdata, updated
                })
                // @ts-ignore
                updated[indice][I18NIDS[i18n]].data.ext1 = extdata
                setMissionCampos(updated)
              }}  /></>}
          </Col>
          <Col span={12}>
          {data.ext2 === null ? <Result
              status='error'
              title={<>Ingresa el contenido para la <strong>respuesta negativa</strong> en este lugar</>}
              extra={
              <Button type="dashed" onClick={() => {
                setLocal!(true)
                dispatch(setModal({
                  positive: false,
                  missionCampoId: indice
                }))
                setModalTypes!(true)
              }}>
                Seleccionar Recurso
              </Button>
              } /> : <><strong>Acción negativa: </strong><br /><TabContentForm data={data.ext2[0][0].data} i18n={i18n} indice={0} missionCampos={data.ext2} setMissionCampos={(extdata) => {
                const updated = Array.from(missionCampos)
                console.log({
                  extdata, updated
                })
                // @ts-ignore
                updated[indice][I18NIDS[i18n]].data.ext2 = extdata
                setMissionCampos(updated)
              }}  /></> }
          </Col>
        </Row>
      </>}
      {['radio', 'checkbox'].includes(typeLocal) && <>
        <p>Para el ingreso de una opciones, debes ingresar la instrucción asociada al campo.</p>  
        <Input
          // @ts-ignore
          defaultValue={missionCampos[indice][I18NIDS[i18n]].data.text}
          onBlur={({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
            // @ts-ignore
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.text = value
            setMissionCampos(updated)
          }}
          style={{
            marginBottom: 12
          }}
          placeholder={`Ingresa la instrucción asociada a este grupo de radios`} />
        <p>Ingresa la cantidad de opciones disponibles - <span className='InputRemoveRadioOption' onClick={() => {
          const cr = [...radios]
          cr.pop()
          setRadios(cr)
        }}><PlusCircleOutlined /> Quitar última</span> - <span className='InputAddRadioOption' onClick={() => {
          const cr = [...radios]
          cr.push('')
          setRadios(cr)
        }}><PlusCircleOutlined /> Añadir nueva opción</span></p>
        {radios.map((radioopt, ndx) => {
          return <Input
          key={`InputRadio${ndx}`}
          defaultValue={radios[ndx]}
          onBlur={({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
            // @ts-ignore
            const updated = [...radios]
            // @ts-ignore
            updated[ndx] = value
            setRadios(updated)

            // @ts-ignore
            const mainUpdated = Array.from(missionCampos)
            // @ts-ignore
            mainUpdated[indice][I18NIDS[i18n]].data.ext1 = JSON.stringify(updated)
            setMissionCampos(mainUpdated)
          }}
          style={{
            marginBottom: 12
          }}
          placeholder={`Ingresa una opción`} />
        })}
      </>}
      {typeLocal === 'matrix' && <>
        <p>Para mostrar una tabla matrix, debes completar los campos solicitados. <strong>Todos los campos que queden vacíos, se configuran para que el alumno pueda completarlos</strong>.</p>
        <br />
        <span className='InputRemoveRadioOption' onClick={() => {
          if (columns.length > 1) {
            const cr = [...columns]
            cr.pop()
            setColumns(cr)
          } else {
            message.error('Se debe mantener a lo menos una columna en la Matrix.')
          }
        }}><PlusCircleOutlined /> Quitar columna</span> <span className='InputAddRadioOption' onClick={async () => {
          try{
            const columna = await prompt({
              title: 'Nueva columna',
              placeholder: 'Ingresa el nombre de la nueva columna',
              rules: [
                {
                  required: true,
                  message: 'Necesitas ingresar un nombre para la nueva columna'
                }
              ]
            })
            if (columna) {
              const cr = [...columns]
              cr.push({
                key: `col${cr.length}`,
                name: columna,
                editable: false
              })
              setColumns(cr)
            }
            message.success('Se ha añadido la nueva columna')
          } catch (err) {
            message.error('Ingresa un nombre para la nueva columna.')
          }
        }}><PlusCircleOutlined /> Añadir columna</span> <br /><br />
            <ReactDataGrid
              columns={columns}
              rowGetter={(i: any) => rows[i]}           
              enableCellSelect={true}
              // @ts-ignore
              onGridRowsUpdated={({ fromRow, toRow, updated }) => {
                const localRows = rows.slice()
                console.log({ localRows })
                for (let i = fromRow; i <= toRow; i++) {
                  console.log({ i, a: rows[i], updated })
                  localRows[i] = { ...rows[i], ...updated }                  
                }
                setRows(localRows)
                console.log({ localRows, updated, fromRow, toRow })
              }}   
              onCellSelected={(({idx}: any) => {
                const firstColumn = idx === 0
                // @ts-ignore
                document.getElementsByClassName('rdg-selected')[0].style.opacity = firstColumn ? 1 : 0
              })}
              rowsCount={rows.length}
              minHeight={350} />
              <br />

              <span className='InputRemoveRadioOption' onClick={() => {
          const cr = [...rows]
          cr.pop()
          setRows(cr)
        }}><PlusCircleOutlined /> Quitar fila</span>  <span className='InputAddRadioOption' onClick={async () => {
          const cr = [...rows]
          const rowData = columns.map(col => {
            let row = {}
            // @ts-ignore
            row[col.key] = ''
            // @ts-ignore
            return row
          })
          console.log({ rowData })
          cr.push(rowData)
          setRows(cr)
        }}><PlusCircleOutlined /> Añadir fila</span>
      </>}
      {typeLocal === 'likert' && <>
          {/* @ts-ignore */}
        <p>Para el ingreso de una escala de likert, debes ingresar la instrucción asociada al campo.</p>
        <Input
          onBlur={({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
            // @ts-ignore
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.text = value
            setMissionCampos(updated)
          }}
          style={{
            marginBottom: 12
          }}
          placeholder={`Ingresa la instrucción asociada a esta Escala de Likert`} />
        <p>¿Cuál es la máxima numeración? (min: 3, max: 5)</p>
        <InputNumber min={3} max={5} defaultValue={5} onChange={(value) => {
          // @ts-ignore
          const updated = Array.from(missionCampos)
          // @ts-ignore
          updated[indice][I18NIDS[i18n]].data.ext2 = value
          setMissionCampos(updated)
        }} />
        {/*<p>¿Deseas mostrar la escala en íconos?</p>
        <Switch
          defaultChecked
          onChange={(checked: boolean) => {
            // @ts-ignore
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.ext1 = checked
            setMissionCampos(updated)
          }}
          style={{
            marginBottom: 12
          }}/>*/}
        
      </>}

      {typeLocal === 'upload' && <>
        <p>Permite que los participantes suban desde el formulario un archivo.</p>
        <Input
          onBlur={({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
            // @ts-ignore
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.text = value
            setMissionCampos(updated)
          }}
          style={{
            marginBottom: 12
          }}
          // @ts-ignore
          defaultValue={missionCampos[indice][I18NIDS[i18n]].data.text}
          placeholder={`Ingresa la instrucción asociada al campo de archivo adjunto.`} /><br />
        <Input
          onBlur={({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
            // @ts-ignore
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.label = value
            setMissionCampos(updated)
          }}
          style={{
            marginBottom: 12
          }}
          // @ts-ignore
          defaultValue={missionCampos[indice][I18NIDS[i18n]].data.label}
          placeholder={`Nombre del botón de carga.`} />
      </>}

      {typeLocal === 'video' && <>
        <p>Permite que los participantes graben un video desde el formulario.</p>
        <Input
          onBlur={({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
            // @ts-ignore
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.text = value
            setMissionCampos(updated)
          }}
          style={{
            marginBottom: 12
          }}
          // @ts-ignore
          defaultValue={missionCampos[indice][I18NIDS[i18n]].data.text}
          placeholder={`Ingresa la instrucción asociada al campo de archivo adjunto.`} /><br />
        <Input
          onBlur={({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
            // @ts-ignore
            const updated = Array.from(missionCampos)
            // @ts-ignore
            updated[indice][I18NIDS[i18n]].data.label = value
            setMissionCampos(updated)
          }}
          style={{
            marginBottom: 12
          }}
          // @ts-ignore
          defaultValue={missionCampos[indice][I18NIDS[i18n]].data.label}
          placeholder={`Nombre del botón de carga.`} />
      </>}
    </React.Fragment>
  }