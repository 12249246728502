import React, { useEffect } from 'react'

import { 
    HomeOutlined
} from '@ant-design/icons';

import {
    // Card,
    Row,
    Col,
    // Statistic,
    Button,
    Result
} from 'antd';

// import useApi, { UseApiProps } from '../../Utils/useApi';

import udcoDashboard from '../../Assets/udcoDashboard.svg'

import './Dashboard.scss'

type DashboardEndpoint = {
    clientName: string
    inventoriesFinished: number
    procesosActive: any[]
    productsRegistered: number
    usersActive: any[]
    usersRegistered: number
}

/* interface APIDashboardProps extends UseApiProps {
    data: DashboardEndpoint
} */

function Dashboard({ isMobile, exposeSubMenu, setMainLoading }: any) {
    // const { data, loading }: APIDashboardProps = useApi('get', 'dashboard/index')
    
    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Resumen', icon: <HomeOutlined /> }
            ])
        }
        
        return () => {
            //
        }
    }, [exposeSubMenu])

    useEffect(() => {
        setMainLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /*useEffect(() => {
        console.log({ data })
        setMainLoading(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])*/

    return (
        <div className="App">
            <div className="Dashboard">
                <div className="Wrapper">
                    <Row gutter={[16, 16]}>
                        <Col span={24} order={isMobile ? 0 : 5}>
                            <Result
                                status="success"
                                title="¡Bienvenido a tu Administrador de Formularios!"
                                subTitle="Conoce todas las opciones del administrador en el menú superior, si necesitas asistencia, haz click en el botón."
                                icon={<img src={udcoDashboard} className="Dashboard--WelcomeImage" alt="Imagen de bienvenida" />}
                                extra={<Button href='mailto:contingente@contingente.cl' type="ghost">Contáctanos</Button>}
                            />
                        </Col>
                        {/*<Col xs={24} md={6}>
                            <Card size="small" title={<><MobileOutlined /> Usuarios en línea</>}>
                                No hay usuarios conectados realizando inventario en este momento.
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card size="small" title={<><CodeSandboxOutlined /> Inventarios en Curso</>}>
                                No hay procesos de inventario activos en este momento.
                            </Card>
                        </Col>
                        <Col xs={24} md={12}>
                            <Card size="small" title={<><DashboardOutlined /> Configuración para "{data?.clientName}"</>}>
                                <Row gutter={16}>
                                    <Col xs={24} md={8}>
                                        <Statistic title="Licencias en uso" value={data?.usersRegistered} suffix="/ 10" />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Statistic title="Productos registrados" value={data?.productsRegistered} suffix="/ 100,000" />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Statistic title="Inventarios realizados" value={data?.inventoriesFinished + 8} suffix="/ ∞" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>*/}
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Dashboard